import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaCheckCircle, FaDatabase, FaClock, FaComments } from 'react-icons/fa';
import logoImage from '../assets/logo-novaura.png';
import backgroundImage from '../assets/background.png';
import { formatPhoneNumber, cleanPhoneNumber, submitToApiGateway } from '../App';

// Utility Components
const FeatureCard = ({ title, description, icon: Icon }) => (
  <div className="bg-black/60 border border-purple-500/30 backdrop-blur-sm hover:border-purple-400/50 transition duration-300 p-6 rounded-lg">
    <div className="flex items-center gap-4 mb-4">
      <Icon className="h-10 w-10 text-purple-400" />
      <h3 className="text-xl text-purple-400">{title}</h3>
    </div>
    <p className="text-purple-200">{description}</p>
  </div>
);

const FeatureItem = ({ text }) => (
  <div className="flex items-center gap-3">
    <FaCheckCircle className="h-6 w-6 text-green-400 flex-shrink-0" />
    <span className="text-lg text-gray-200">{text}</span>
  </div>
);

const ACSPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    company: '',
    message: ''
  });
  const [submitError, setSubmitError] = useState(null);
  const [consentGiven, setConsentGiven] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'phoneNumber' ? formatPhoneNumber(value) : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError(null);

    if (!consentGiven) {
      setSubmitError('Please agree to the terms before submitting.');
      return;
    }

    // Clean the phone number before submission
    const cleanedFormData = {
      ...formData,
      phoneNumber: cleanPhoneNumber(formData.phoneNumber)
    };

    const result = await submitToApiGateway(cleanedFormData);
    if (result.success) {
      navigate('/thank-you');
    } else {
      setSubmitError('There was an error submitting your form. Please try again later.');
    }
  };

  return (
    <div className="relative min-h-screen bg-black text-white overflow-hidden">
      {/* Background */}
      <div
        className="absolute inset-0 z-0 bg-cover bg-center opacity-70"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
      <div className="absolute inset-0 z-0 bg-gradient-to-b from-purple-900/30 to-black/80" />

      <div className="relative z-10 container mx-auto px-4 py-8">
        {/* Header */}
        <div className="flex justify-start mb-16">
          <Link to="/">
            <img src={logoImage} alt="Novaura Logo" className="h-8 w-auto" />
          </Link>
        </div>

        {/* Main Header */}
        <header className="text-center mb-16">
          <h1 className="text-4xl md:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600 mb-4">
            Autonomous Communication System (ACS)
          </h1>
          <p className="text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto">
            A next-gen AI-powered communication system that integrates Voice, Text, Email, and Chat into a seamless
            platform for intelligent customer engagement.
          </p>
        </header>

        {/* Main Content */}
        <main>
          {/* Problem & Solution */}
          <section className="grid md:grid-cols-2 gap-8 mb-20">
            <div className="bg-black/60 border border-purple-500/30 backdrop-blur-sm p-6 rounded-lg">
              <h2 className="text-2xl text-red-400 mb-4">The Problem</h2>
              <p className="text-gray-300">
                Businesses struggle with ineffective lead generation, muddy data, high customer acquisition costs, and
                inconsistent customer engagement, leading to missed opportunities and lower conversion rates.
              </p>
            </div>

            <div className="bg-black/60 border border-purple-500/30 backdrop-blur-sm p-6 rounded-lg">
              <h2 className="text-2xl text-pink-500 mb-4">The Solution</h2>
              <p className="text-gray-300">
                Novaura's AI-powered communication system uses custom-built agents to streamline data, qualify leads
                and enhance customer engagement, delivering personalized, consistent, and scalable customer
                interactions that drive conversions.
              </p>
            </div>
          </section>

          {/* Platform Overview */}
          <section className="mb-20">
            <div className="text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold mb-4">Platform Overview</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Built on Novaura's proprietary CRM + Customer Data Platform (CDP) for data-driven automation.
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-6">
              <FeatureCard
                title="Intelligent Engagement"
                description="Our AI agents handle conversations across all channels with human-like understanding."
                icon={FaComments}
              />
              <FeatureCard
                title="Data Integration"
                description="Seamlessly connects with your existing CRM systems to enrich customer data."
                icon={FaDatabase}
              />
              <FeatureCard
                title="24/7 Availability"
                description="Never miss another opportunity with round-the-clock communication capabilities."
                icon={FaClock}
              />
            </div>
          </section>

          {/* Features List */}
          <section className="mb-20">
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">Key Features & Benefits</h2>
            <div className="grid md:grid-cols-2 gap-x-12 gap-y-8">
              {[
                "24/7 Accessibility",
                "Advanced Linguistics Processor",
                "AI Call Summaries & Recordings",
                "Continuous Learning",
                "Custom Data Recognition",
                "Human-like Conversations",
                "Knowledge Base Integration",
                "Multi-lingual Support",
                "No People Management",
                "Off-script Capabilities",
                "Seamless API Integrations",
                "Zero Unanswered Calls"
              ].map((feature, index) => (
                <FeatureItem key={index} text={feature} />
              ))}
            </div>
          </section>

          {/* Contact Form */}
          <section id="contact-form" className="max-w-2xl mx-auto">
            <div className="bg-black/60 border border-purple-500/30 backdrop-blur-sm p-8 rounded-lg">
              <h2 className="text-2xl text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600 mb-6">
                Get in Touch
              </h2>
              
              <form onSubmit={handleSubmit} className="space-y-6">
                {['firstName', 'lastName', 'phoneNumber', 'email', 'company'].map((field) => (
                  <div key={field} className="mb-4">
                    <label className="block text-purple-400 mb-2" htmlFor={field}>
                      {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                    </label>
                    <input
                      type={field === 'email' ? 'email' : 'text'}
                      id={field}
                      name={field}
                      value={formData[field]}
                      onChange={handleInputChange}
                      placeholder={`Enter ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`}
                      className="w-full px-4 py-2 bg-black/50 border border-purple-500/30 rounded-md focus:border-purple-400 text-pink-200 outline-none"
                      required
                    />
                  </div>
                ))}

                <div className="mb-4">
                  <label className="block text-purple-400 mb-2" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 bg-black/50 border border-purple-500/30 rounded-md focus:border-purple-400 text-pink-200 outline-none min-h-[100px]"
                  />
                </div>

                <div className="mb-4">
                  <label className="flex items-start">
                    <input
                      type="checkbox"
                      checked={consentGiven}
                      onChange={(e) => setConsentGiven(e.target.checked)}
                      className="mr-2 mt-1"
                    />
                    <span className="text-purple-200 text-sm">
                      By submitting this form, you consent to receive texts, emails, and phone calls from Novaura regarding our services. Message and data rates may apply. Reply STOP to cancel at any time.
                    </span>
                  </label>
                </div>

                {submitError && (
                  <div className="mb-4 text-red-500">
                    Error: {submitError}
                  </div>
                )}

                <div className="flex justify-between gap-4">
                  <button
                    type="button"
                    onClick={() => navigate('/')}
                    className="px-4 py-2 bg-black/50 border border-purple-500/30 text-purple-400 rounded-md hover:border-purple-400 transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="w-full bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 text-white py-3 rounded-md transition-colors"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </section>
        </main>

        {/* Footer */}
        <footer className="mt-24 text-center text-gray-400">
          <div className="flex justify-center space-x-6 mb-6">
            <Link to="/privacy" className="hover:text-purple-400 transition">
              Privacy Policy
            </Link>
            <Link to="/terms" className="hover:text-purple-400 transition">
              Terms & Conditions
            </Link>
          </div>
          <p>© {new Date().getFullYear()} Novaura. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default ACSPage; 
import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from '../assets/background.png';
import logoImage from '../assets/logo-novaura.png';

const TermsAndConditions = () => {
  return (
    <div 
      className="min-h-screen bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="container mx-auto px-4 py-8">
        <Link to="/" className="block mb-8">
          <img src={logoImage} alt="NOVAURA" className="h-16" />
        </Link>
        
        <div className="bg-white/90 backdrop-blur-sm rounded-lg shadow-lg p-8 mb-8">
          <h1 className="text-3xl font-bold mb-6">Terms & Conditions</h1>
          
          <div className="prose max-w-none">
            <p className="font-semibold mb-4">
              PLEASE READ THE FOLLOWING INFORMATION CAREFULLY BEFORE ACCESSING THIS WEBSITE OR USING THE INFORMATION CONTAINED HEREIN.
            </p>

            <p className="mb-6">
              These Terms and Conditions govern your use of the Novaura, LLC website and any services offered through it. By accessing our website or using our services, you agree to be legally bound by these Terms, our Privacy Policy, and any other underlying agreements or terms. If you do not agree to these terms, please refrain from using our website. Novaura reserves the right to modify these Terms at any time without prior notice, and your continued use of the website constitutes acceptance of any changes. We encourage you to review these Terms periodically.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Use and Access to Website</h2>
            <p className="mb-6">
              You may access our website without registering any details. The website and the information contained within are provided by Novaura, LLC ("Novaura") as a service to visitors and may be used solely for informational purposes, subject to these Terms. By reviewing, printing, or downloading any content, you agree to comply with these Terms. You are granted a limited, non-transferable license to download or print one copy of the website content for personal, non-commercial use. However, you may not modify, distribute, publicly display, sell, or rent any content from our website without prior written permission. Any unauthorized use, including copying, reproducing, or distributing materials without express permission, is strictly prohibited. Novaura does not grant any express or implied rights under any patents, copyrights, trademarks, or trade secrets.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Disclaimer of Warranties and Limitation of Liability</h2>
            <p className="mb-6">
              All content and materials on the website are provided "as is," without warranties of any kind, whether express or implied, including but not limited to warranties of merchantability, non-infringement of intellectual property, or fitness for a particular purpose. Novaura does not warrant that the website will be error-free or uninterrupted, nor that any files or content available for download will be free of viruses or other harmful elements. To the fullest extent permitted by law, Novaura disclaims any liability for damages of any kind arising from the use or inability to use this website, including direct, indirect, incidental, consequential, or punitive damages.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Data Collection and Consumer Information</h2>
            <p className="mb-6">
              By using our website or engaging with Novaura's services, you consent to the collection, processing, and storage of personal and non-personal data. This may include, but is not limited to, personal identifiable information (such as name, email, phone number, and mailing address), demographic data, behavioral data (such as website interactions and purchase history), device and technical information (such as IP address, browser type, and operating system), and communication preferences. This data may be collected through web forms, surveys, cookies, tracking pixels, AI-powered chatbots, and third-party analytics providers. We collect and use this information to enhance our services, facilitate marketing and promotional efforts, conduct market research, and improve customer support.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">User Consent and Opt-Out Rights</h2>
            <p className="mb-6">
              By providing your contact information, you expressly consent to receive communications from Novaura, including marketing messages, SMS or text messages, email newsletters, and service-related notifications. These communications may be sent via automated technology, including AI-powered messaging, chatbots, or prerecorded messages. Your consent to receive such communications is not a condition of purchasing any goods or services. You may opt out at any time by replying "STOP" to SMS messages, clicking the unsubscribe link in emails, or contacting us at support@novaura.io. Even if you opt out of marketing communications, Novaura may still send you transactional or service-related messages.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Privacy, Security, and Third-Party Sharing</h2>
            <p className="mb-6">
              Novaura takes reasonable administrative, technical, and physical measures to protect your data. However, we cannot guarantee absolute security, and Novaura shall not be responsible for unauthorized access, loss, or theft of user data resulting from cyberattacks, third-party breaches, or other security incidents beyond our control. In some cases, we may share user data with third-party service providers, marketing partners, analytics platforms, or advertisers to improve our offerings. Data sharing is conducted in compliance with applicable privacy laws, including the California Consumer Privacy Act (CCPA) and the General Data Protection Regulation (GDPR). Users have the right to request access to their data, request deletion, or opt out of third-party data sharing by contacting privacy@novaura.io.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Marketing, Retargeting, and AI-Powered Services</h2>
            <p className="mb-6">
              By interacting with Novaura's website and services, you consent to the use of cookies, tracking pixels, and other technologies for marketing and analytics purposes. This may include behavioral targeting, retargeting, and customized advertising based on your interactions with our website. Additionally, Novaura may use AI-powered chatbots and automated voice agents for customer support and lead qualification. You can manage your cookie preferences through your browser settings or opt out of targeted advertising by following the instructions provided by digital advertising platforms.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Website Uptime and Availability</h2>
            <p className="mb-6">
              Novaura aims to maintain website uptime 24/7; however, we do not guarantee uninterrupted access. The website may be temporarily unavailable due to maintenance, system failures, or external factors beyond our control. We will make reasonable efforts to notify users of scheduled maintenance, but we are not obligated to do so.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">User Conduct</h2>
            <p className="mb-6">
              You agree not to use the website for any unlawful or unauthorized activities, including but not limited to hacking, data scraping, interfering with website operations, or using automated bots to access or extract content. Novaura reserves the right to prohibit or prevent any unauthorized activity and may take legal action against violators. We will cooperate with law enforcement authorities in any investigation of unlawful activities involving our website.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Trademarks and Intellectual Property</h2>
            <p className="mb-6">
              All trademarks, service marks, and logos appearing on the Novaura website are the property of Novaura, LLC or their respective owners. Unauthorized use of any trademarks, logos, or proprietary content is strictly prohibited and may be subject to legal action.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Forward-Looking Statements</h2>
            <p className="mb-6">
              Some content on this website may contain forward-looking statements related to Novaura's expected future business performance or industry outlook. These statements are based on assumptions and involve uncertainties that could result in outcomes differing from projections. Novaura does not undertake an obligation to update or revise forward-looking statements.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Limitation of Liability and Release</h2>
            <p className="mb-6">
              To the fullest extent permitted by law, you agree to release, indemnify, and hold Novaura, its affiliates, officers, directors, employees, agents, and partners harmless from any claims, liabilities, damages, losses, or expenses arising out of your use of the website or services. This includes but is not limited to claims relating to unauthorized access, service interruptions, data loss, or reliance on website content.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Termination of Use</h2>
            <p className="mb-6">
              Novaura reserves the right to terminate or suspend access to its website and services, with or without notice, for any reason, including violations of these Terms. Upon termination, you must discontinue all use of the website, and any rights granted under these Terms will cease immediately.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Governing Law and Dispute Resolution</h2>
            <p className="mb-6">
              These Terms shall be governed by and construed in accordance with the laws of the State of Connecticut, without regard to conflict of law principles. Any disputes arising from these Terms shall be resolved exclusively in the courts of Connecticut. If any provision of these Terms is deemed unenforceable, the remaining provisions shall remain in full force and effect.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Contact Information</h2>
            <p className="mb-6">
              For any questions regarding these Terms & Conditions, please contact us at support@novaura.io.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions; 
import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from '../assets/background.png';
import logoImage from '../assets/logo-novaura.png';

const PrivacyPolicy = () => {
  return (
    <div 
      className="min-h-screen bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="container mx-auto px-4 py-8">
        <Link to="/" className="block mb-8">
          <img src={logoImage} alt="NOVAURA" className="h-16" />
        </Link>
        
        <div className="bg-white/90 backdrop-blur-sm rounded-lg shadow-lg p-8 mb-8">
          <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
          <p className="text-sm text-gray-600 mb-8">Last updated: March 17, 2025</p>
          
          <div className="prose max-w-none">
            <p className="mb-6">
              This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Interpretation and Definitions</h2>
            
            <h3 className="text-xl font-bold mt-6 mb-3">Interpretation</h3>
            <p className="mb-6">
              The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>

            <h3 className="text-xl font-bold mt-6 mb-3">Definitions</h3>
            <p className="mb-4">For the purposes of this Privacy Policy:</p>
            <ul className="list-disc pl-6 mb-6 space-y-2">
              <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
              <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Novaura, LLC.</li>
              <li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
              <li><strong>Country</strong> refers to: Connecticut, United States</li>
              <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
              <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
              <li><strong>Service</strong> refers to the Website.</li>
              <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
              <li><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</li>
              <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
              <li><strong>Website</strong> refers to Novaura.io, accessible from https://www.novaura.io</li>
              <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
            </ul>

            <h2 className="text-2xl font-bold mt-8 mb-4">Collecting and Using Your Personal Data</h2>
            
            <h3 className="text-xl font-bold mt-6 mb-3">Types of Data Collected</h3>
            
            <h4 className="text-lg font-bold mt-4 mb-2">Personal Data</h4>
            <p className="mb-4">
              While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
            </p>
            <ul className="list-disc pl-6 mb-6">
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Usage Data</li>
            </ul>

            <h4 className="text-lg font-bold mt-4 mb-2">Usage Data</h4>
            <p className="mb-6">
              Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data. When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data. We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
            </p>

            <h4 className="text-lg font-bold mt-4 mb-2">Tracking Technologies and Cookies</h4>
            <p className="mb-6">
              We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies: Cookies: What Do They Do?.
            </p>

            <h3 className="text-xl font-bold mt-6 mb-3">Use of Your Personal Data</h3>
            <p className="mb-4">The Company may use Personal Data for the following purposes:</p>
            <ul className="list-disc pl-6 mb-6 space-y-2">
              <li><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
              <li>To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
              <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
              <li><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</li>
              <li><strong>For other purposes:</strong> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, training and improving our AI agents, and to evaluate and improve our Service, products, services, marketing and your experience.</li>
            </ul>

            <p className="mb-4">We may share Your personal information in the following situations:</p>
            <ul className="list-disc pl-6 mb-6 space-y-2">
              <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
              <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
              <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
              <li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
              <li><strong>With Your consent:</strong> We may disclose Your personal information for any other purpose with Your consent.</li>
            </ul>

            <h2 className="text-2xl font-bold mt-8 mb-4">Retention of Your Personal Data</h2>
            <p className="mb-6">
              The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
            </p>
            <p className="mb-6">
              The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Transfer of Your Personal Data</h2>
            <p className="mb-6">
              Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction. Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer. The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Disclosure of Your Personal Data</h2>
            
            <h3 className="text-xl font-bold mt-6 mb-3">Business Transactions</h3>
            <p className="mb-6">
              If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
            </p>

            <h3 className="text-xl font-bold mt-6 mb-3">Law enforcement</h3>
            <p className="mb-6">
              Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
            </p>

            <h3 className="text-xl font-bold mt-6 mb-3">Other legal requirements</h3>
            <p className="mb-4">The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
            <ul className="list-disc pl-6 mb-6">
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of the Company</li>
              <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
              <li>Protect the personal safety of Users of the Service or the public</li>
              <li>Protect against legal liability</li>
            </ul>

            <h2 className="text-2xl font-bold mt-8 mb-4">Security of Your Personal Data</h2>
            <p className="mb-6">
              The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Choice/Opt-out</h2>
            <p className="mb-6">
              Company provides you the opportunity to 'opt-out' of having your personally identifiable information used for certain purposes, when it asks for this information. For example, if you purchase a product/service but do not wish to receive any additional marketing material from us, you can indicate your preference by emailing or calling us per the information contained on our contact page. If you no longer wish to receive our promotions, you may opt-out of receiving it by following the instructions included in each newsletter or communication or by emailing or calling us per the information contained on our contact page.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Children's Privacy</h2>
            <p className="mb-6">
              Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers. If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Links to Other Websites</h2>
            <p className="mb-6">
              Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Changes to this Privacy Policy</h2>
            <p className="mb-6">
              We may update Our Privacy Policy from time to time. Any changes to this Privacy Policy will be posted on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page, unless otherwise stated.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Contact Us</h2>
            <p className="mb-6">
              If you have any questions about this Privacy Policy, You can contact us:<br />
              By email: support@novaura.io
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">California User Consumer Rights</h2>
            <p className="mb-6">
              California Civil Code Section 1798.83 permits California residents who have provided Personal Information to Company or third parties under a list management arrangement with Company to request certain information regarding Company's disclosure of such information to third parties for their direct marketing purposes. Within thirty days of receiving such a request, We will provide a list of the categories of Personal Information disclosed to third parties for third-party direct marketing purposes during the immediately preceding calendar year, along with the names and addresses of these third parties. This request may be made no more than once per calendar year. We reserve our right not to respond to requests submitted other than to the address specified in this section. If you have questions about this policy, please contact Us.
            </p>

            <p className="mb-4">California residents may also take advantage of the following rights:</p>
            <ul className="list-disc pl-6 mb-6 space-y-2">
              <li>You may request, up to two times each year, that we disclose to you the categories and specific pieces of personal information that We have collected about you, the categories of sources from which your personal information is collected, the business or commercial purpose for collecting your personal information, the categories of personal information that we disclosed for a business purpose, any categories of personal information that We sold about You, the categories of third parties with whom we have shared your personal information, and the business or commercial purpose for selling your personal information, if applicable.</li>
              <li>You may request that We delete any personal information that we have collected from or about you. Note that there are some reasons we will not be able to fully address your request, such as if we need to complete a transaction for you, to detect and protect against fraudulent and illegal activity, to exercise our rights, or to comply with a legal obligation.</li>
              <li>You may request to opt out of Our sale of your personal information to third parties. This means that, if you opt out, going forward, we will not share your information with such third parties to use for their own commercial purposes unless you later direct us send an and verify your identity according to the steps outlined in the next paragraph.</li>
            </ul>

            <p className="mb-6">
              For verification purposes, please send an email with the subject of the email titled: "CCPA Opt Out". In that email, please provide Your name, address, telephone number and email address. Further information may be required for verification. Additionally, please let us know whether this request is for Yourself or on behalf of another person. We value your privacy and will not discriminate in response to your exercise of your privacy rights. We will respond to your access and deletion requests within 45 days of receipt of your request, after proper verification, unless we need additional time, in which case We will let you know. We will respond to Your opt out of sale requests as soon as reasonably possible.
            </p>

            <p className="mb-4">For purposes of compliance with the California Consumer Privacy Act, in addition to the further details as described throughout this Privacy Policy, we make the following disclosures:</p>
            <ul className="list-disc pl-6 mb-6">
              <li>We collect the following categories of personal information: Identifiers/Contact Information, Commercial information (like your transactional history and service usage), Internet or other electronic network activity information, geolocation, visual and audio information, and profiles and inferences drawn from the above.</li>
              <li>We disclose the following categories of personal information for a business or commercial purpose: Identifiers/Contact Information, Commercial information, Internet or other electronic network activity information, geolocation, visual and audio information, and profiles and inferences drawn from above</li>
              <li>We disclose the following categories of personal information to third parties for marketing purposes: Identifiers/Contact Information, Commercial information, Internet or other electronic network activity information, geolocation, visual and audio information, and profiles and inferences drawn from above, which may be considered a "sale" of information under the California Consumer Privacy Act.</li>
            </ul>

            <h2 className="text-2xl font-bold mt-8 mb-4">Virginia Privacy Rights</h2>
            <p className="mb-6">
              If you are a resident of Virginia, you have the right under the Virginia Consumer Data Protection Act, upon a verified request, to:
            </p>
            <ul className="list-disc pl-6 mb-6">
              <li>To confirm whether or not a controller is processing your personal data and to access such personal data;</li>
              <li>To correct inaccuracies in your personal data;</li>
              <li>To delete your personal data;</li>
              <li>To obtain a copy of your personal data that you previously provided to us in a portable, and if technically feasible, readily usable format, if processing is carried out by automated means;</li>
              <li>To opt out of the processing of your personal data for purposes of (i) targeted advertising, (ii) the sale of personal data, or (iii) profiling in furtherance of decisions that produce legal or similarly significant effects concerning the consumer.</li>
            </ul>

            <p className="mb-6">
              To exercise these rights, you may make a request to confirm, access, correct, delete, obtain a copy, or opt-out of the processing of your Personal Information for targeting advertising, sale, or profiling by contacting us using the contact information contained herein.
            </p>

            <p className="mb-6">
              We may require you to confirm your identity and your residency in order to obtain the information, and you are only entitled to make this request up to twice annually. For emails, please include "Virginia Privacy Rights" as the subject line. You must include your full name, email address, and attest to the fact that you are a Virginia resident. We will process your request within forty-five (45) days or let you know if we need additional time or cannot process your request. If you make this request by telephone, we may also ask you to provide the request in writing so that we may verify your identity. If we are unable to honor your request for any reason, we will notify you of the reason within the request time period.
            </p>

            <p className="mb-6">
              If we decline to take action on your request, residents of Virginia can appeal our decision by submitting an email to the email listed herein entitled "Virginia Privacy Rights Appeal" and we will review your request and respond with a written explanation of the reasons for our decision. If your appeal is denied, you may contact the Virginia Attorney General to submit a complaint.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy; 